const MenuLinks = [
  {
    to: '/',
    name: 'Start',
    full: 'Strona główna',
  },
  {
    to: '/aplikacje-mobilne/',
    name: 'Apps',
    full: 'Aplikacje mobilne',
  },
  {
    to: '/strony-internetowe/',
    name: 'WWW',
    full: 'Strony internetowe',
  },
  {
    to: '/aplikacje-progresywne-pwa/',
    name: 'PWA',
    full: 'Aplikacje Progresywne (PWA)',
  },
  {
    to: '/aplikacje-serwerowe/',
    name: 'SRV',
    full: 'Aplikacje serwerowe',
  },
  {
    to: '/systemy-crm-erp/',
    name: 'ERP',
    full: 'Systemy CRM/ERP',
  },
  {
    to: '/wdrozenia-odoo/',
    name: 'ODOO',
    full: 'Wdrożenia Odoo',
    sub: true,
  },
  {
    to: '/wdrozenia-nextcloud/',
    name: 'NEXTCLOUD',
    full: 'Wdrożenia Nextcloud',
    sub: true,
  },
  {
    to: '/technologie/',
    name: 'Tech',
    full: 'Nasze technologie',
  },
  {
    to: '/devops/',
    name: 'DEVOPS',
    full: 'Usługi DevOps',
  },
  {
    to: '/consulting-it/',
    name: 'CONSULT',
    full: 'Consulting IT',
  },
  {
    to: '/blog/',
    name: 'BLOG',
    full: 'Blog',
  },
  {
    to: '/kontakt/',
    name: 'KONTAKT',
    full: 'Kontakt',
  },
];

export default MenuLinks;
