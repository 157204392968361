/* eslint-disable react/prop-types */
import { Drawer } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import MenuIcon from '@material-ui/icons/Menu';
import OpenInNew from '@material-ui/icons/OpenInNew';
import LogoTxt from '../../assets/svg/logo-txt.svg';
import MenuLinks from './menu-links';
import LogoMain from '../../assets/svg/logo-full-alt-big.svg';

const Menu = ({ path }) => {
  const [open, setOpen] = useState(false);
  // const [showHeader, setShowHeader] = useState(() => path.indexOf('blog') > -1);
  const [showHeader, setShowHeader] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 20;
      if (show) {
        setShowHeader(true);
      } else {
        // setShowHeader(false);
      }
    };
    // document.addEventListener('scroll', handleScroll);
    return () => {
      // document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Drawer open={open} onClose={() => { setOpen(false); }} transitionDuration={300}>
        <div style={{ textAlign: 'center', height: '140px' }}>
          <LogoMain style={{ width: '120px', height: '120px', marginTop: '20px' }} />
        </div>
        <ul id="nav-mobile" className="sidenav">
          {MenuLinks.map((page) => (
            <li key={page.to}>
              <Link className={page.sub ? 'gtb-sb' : ''} partiallyActive={page.to !== '/'} activeClassName="gtb-ac" to={page.to} onClick={() => { setOpen(false); }}>{page.full}</Link>
            </li>
          ))}
          <li key="wow">
            <a href="https://iqcode.io" lang="en">
              iqcode.io
              <OpenInNew style={{ verticalAlign: '-12%', width: '18px', marginLeft: '3px' }} />
            </a>
          </li>
        </ul>
      </Drawer>
      <nav className={showHeader ? '' : 'transparent'} role="navigation" id="navbar">
        <div className="nav-wrapper">
          <a id="logo-container" href="/" className="brand-logo">
            <LogoTxt />
          </a>
          <div className="menu-test-container">
            <ul id="menu-test" className="right hide-on-med-and-down">
              {MenuLinks.map((page) => (page.sub ? null
                : (
                  <li key={page.to}>
                    <Link activeClassName="gtb-ac" partiallyActive={page.to !== '/'} to={page.to}>{page.name}</Link>
                  </li>
                )
              ))}
              <li key="wow">
                <a href="https://iqcode.io" lang="pl-PL">
                  ENG
                  <OpenInNew style={{ verticalAlign: '-12%', width: '18px', marginLeft: '3px' }} />
                </a>
              </li>
            </ul>
          </div>

          <a
            onClick={() => {
              setOpen(true);
            }}
            className="sidenav-trigger"
          >
            <MenuIcon />
          </a>
        </div>
      </nav>
    </>
  );
};

export default Menu;
