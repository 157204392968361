// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-blog-single-jsx": () => import("./../../../src/layouts/blog-single.jsx" /* webpackChunkName: "component---src-layouts-blog-single-jsx" */),
  "component---src-layouts-tags-jsx": () => import("./../../../src/layouts/tags.jsx" /* webpackChunkName: "component---src-layouts-tags-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aplikacje-mobilne-jsx": () => import("./../../../src/pages/aplikacje-mobilne.jsx" /* webpackChunkName: "component---src-pages-aplikacje-mobilne-jsx" */),
  "component---src-pages-aplikacje-progresywne-pwa-jsx": () => import("./../../../src/pages/aplikacje-progresywne-pwa.jsx" /* webpackChunkName: "component---src-pages-aplikacje-progresywne-pwa-jsx" */),
  "component---src-pages-aplikacje-serwerowe-jsx": () => import("./../../../src/pages/aplikacje-serwerowe.jsx" /* webpackChunkName: "component---src-pages-aplikacje-serwerowe-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-consulting-it-jsx": () => import("./../../../src/pages/consulting-it.jsx" /* webpackChunkName: "component---src-pages-consulting-it-jsx" */),
  "component---src-pages-devops-jsx": () => import("./../../../src/pages/devops.jsx" /* webpackChunkName: "component---src-pages-devops-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-strony-internetowe-jsx": () => import("./../../../src/pages/strony-internetowe.jsx" /* webpackChunkName: "component---src-pages-strony-internetowe-jsx" */),
  "component---src-pages-systemy-crm-erp-jsx": () => import("./../../../src/pages/systemy-crm-erp.jsx" /* webpackChunkName: "component---src-pages-systemy-crm-erp-jsx" */),
  "component---src-pages-technologie-jsx": () => import("./../../../src/pages/technologie.jsx" /* webpackChunkName: "component---src-pages-technologie-jsx" */),
  "component---src-pages-wdrozenia-nextcloud-jsx": () => import("./../../../src/pages/wdrozenia-nextcloud.jsx" /* webpackChunkName: "component---src-pages-wdrozenia-nextcloud-jsx" */),
  "component---src-pages-wdrozenia-odoo-jsx": () => import("./../../../src/pages/wdrozenia-odoo.jsx" /* webpackChunkName: "component---src-pages-wdrozenia-odoo-jsx" */)
}

